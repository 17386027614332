.tabela-simples{

	border-collapse: collapse;
	width: 100%;
	border: 1px solid #e9ecef;


	th, td {
		padding: 5px;
		text-align: left;
		border: 1px solid #e9ecef;	
	}
}

.tabela-div-horizontais{
	width: 100%;
	border-collapse: collapse;
	th, td {
		padding: 5px;
		border-bottom: 1px solid #e9ecef;
	}
	
	td{
		height: 50px;
	}
}